@import url('https://fonts.googleapis.com/css2?family=Volkhov&display=swap');
*::-webkit-scrollbar {
  display: none;
}
* {
  line-height: 1.1;
}

body {
  margin: 0;
  font-family: 'Volkhov', serif; /* font-family: 'PT Serif', serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #edc8b5;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

li {
  list-style: none;
}

code {
  font-family: 'Volkhov', serif; /* font-family: 'PT Serif', serif; */
}

h2 {
  margin: 0;
  padding: 0;
}

a.homepage {
  color: #123c69;
  /* cursor: default; */
  outline: none;
  -webkit-tap-highlight-color: transparent;
  transition: transform 250ms;
}

a.homepage:hover {
  transform: translateY(-2px);
}

.playlist {
  background: #edc7b7;
  min-height: 100vh;
  justify-content: center;
  color: #123c69;
  text-align: center;
}

.contacts_playlist_container {
      margin-top: 50px;

  min-height: 100px;
  position: absolute; /* Position the footer absolutely */
  bottom: 0; /* Stick it to the bottom */
  min-width: 100%;
  background: #ac3b61;
  display: flex;
  align-items: center;
  justify-content: center;
}
.playlist-greeting_container {
  padding: 30px;
}

.playlist-hint {
  padding-bottom: 20px;
}

.spotify_container {
  display: flex;
  width: 75%;
  height: 50%;
  background: #f6e3db;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  align-items: center;
  box-shadow: 1px 1px 2px #333;
  border-radius: 12px;
}

.spotify_column {
  flex: 1;
  margin: 10px;
}

.spotify_iframe {
  font-family: 'spotify-circular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 2rem;
  -webkit-font-smoothing: antialiased;
  color: rgb(195, 194, 192);
  border-radius: 12px;
  transition: transform 400ms;
  /* position: relative; */
}

#gr_updates_widget {
  font-family: 'spotify-circular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  border-radius: 5px;
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 4px 1px #595959, inset 0px 0px 0px 1px #7d730b;
  -moz-box-shadow: 0px 0px 4px 1px #595959, inset 0px 0px 0px 1px #7d730b;
  box-shadow: 0px 0px 4px 1px #595959, inset 0px 0px 0px 1px #7d730b;
  padding: 20px 10px 35px 15px;
  width: 250px;
  height: 330px;
}
#gr_footer {
  margin-bottom: 10px;
}
#gr_updates_widget p {
  padding: 0px;
  margin: 0;
  font-size: 14px;
}
#gr_footer img {
  margin-top: 1 0px;
  width: 100px;
  float: left;
}
#gr_updates_widget img {
  border-style: none;
}

/**********************************************MOBILE******************************************/
@media only screen and (max-width: 786px) {
  .landing {
    background: #edc7b7;
    min-height: 100vh;
    justify-content: center;
  }

  .header_btns {
    position: relative;
    width: auto;
    right: 22px;
    margin: 0px;
    padding: 0px;
  }

  .landing_wave {
    min-width: 100%;
    position: absolute;
    bottom: 0px;
    right: 0px;
    pointer-events: none;
  }

  .type-container {
    color: #123c69;
    position: absolute;
    left: -10%;
    padding-top: 50px;
    word-wrap: break-word;
    overflow: hidden;
  }
  .about_me_paragraph {
    left: 0;
    padding: 15px;
    text-align: center;
  }

  .static_about_me_paragraph {
    margin: 25px;
  }

  .about_me_paragraph_container {
    text-align: center;
    font-size: 20px;
    word-wrap: break-word;
    margin: 15px;
  }
  .heading_container {
    min-height: 700px;
    position: relative;
    overflow: hidden;
  }
  .about_1 {
    padding-left: 0px;
  }
  .about_2 {
    padding-left: 0px;
  }
  .about_3 {
    padding-left: 0px;
  }
  .about_4 {
    padding-left: 0px;
  }
  .about_5 {
    padding-left: 0px;
  }
  .about_6 {
    padding-left: 0px;
  }

  /*********SPOTIFY*********/
  .contacts_playlist_container {
        margin-top: 50px;

    min-height: 100px;
    position: relative; /* Position the footer absolutely */
    bottom: 0; /* Stick it to the bottom */
    min-width: 100%;
    background: #ac3b61;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .spotify_container {
    flex-direction: column;
    text-align: center;
    min-height: 60vh;
    padding: 0px;
    position: relative;
  }
  .project_container {
    justify-content: center;
    position: relative;
    background: #123c69;
    color: #494143;
    align-items: center;
    padding-bottom: 100px;
  }
  .card_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 60vh;
    padding: 0px;
    margin: 0px;
  }

  .contacts_container {
    /* width: auto; */
    /* padding: 25px 50px; */
    flex-shrink: 0; /* Prevent the footer from shrinking when content is tall */
    background: #ac3b61;
    min-height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .project_container {
    justify-content: center;
    position: relative;
    background: #123c69;
    color: #ac3b61;
    min-height: 1700px;
    align-items: center;
    width: 100%;
  }

  .card_container {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .project-greetings {
    font-size: 50px;
    padding-top: 40px;
    text-align: center;
  }
  .project-hint {
    padding-top: 30px;
    text-align: center;
  }
}
/***************************************************************************************/
/****************************************4K**********************************************/

@media only screen and (min-width: 2540px) {
  *::-webkit-scrollbar {
    display: none;
  }
  * {
    line-height: 1.1;
  }

  .contacts_playlist_container {
        margin-top: 50px;

    min-height: 100px;
    position: absolute; /* Position the footer absolutely */
    bottom: 0; /* Stick it to the bottom */
    min-width: 100%;
    background: #ac3b61;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #edc8b5;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  li {
    list-style: none;
  }

  h2 {
    margin: 0;
    padding: 0;
  }
  /****************************************LANDING*********************************************/
  .landing {
    background: #edc7b7;
    min-height: 100vh;
    justify-content: center;
  }

  .landing_wave {
    width: 40%;
    position: absolute;
    bottom: 0px;
    right: 0px;
  }

  .navigation {
    float: right;
  }
  .nav {
    height: 100px;
    width: auto;
    display: flex;
    justify-content: space-around;
    text-align: center;
    margin: 20px auto;
    float: right;
  }

  .buttonCode {
    color: #123c69;
    font-size: 40px;
    text-decoration: none;
    background: inherit;
    padding: 15px;
    margin: 15px;
    border-radius: 20px;
    display: inline-block;
    transition: all 0.4s ease 0s;
  }

  .navLinks {
    cursor: pointer;
  }

  .buttonCode:hover {
    color: #edc7b7;
    background: #123c69;
    border-color: #123c69;
    transition: all 0.4s ease 0s;
  }

  .front_image {
    position: absolute;
    margin: auto;
    left: 50%;
    margin-right: auto;
    margin-left: auto;
    max-width: 400px;
    max-height: 300px;
  }

  .type-container {
    color: #123c69;
    position: absolute;
    top: 15%;
    left: 20%;
    padding: 100px;
    overflow: hidden;
    font-size: 60px;
    /* max-width: 100%; */
  }
  .typewriter_name {
    padding: 40px;
  }
  .typewriter_role {
    padding: 40px;
  }
  .typewriter_welcome {
    padding: 40px;
  }
  /*************************************************************************************/

  /****************************************ABOUT ME*********************************************/

  .welcome {
    background: #bab2b5;
    color: #123c69;
    display: flex;
    justify-content: center;
    min-height: 1000px;
    width: 100%;
  }

  .element_welcome_container {
    width: 100%;
    justify-content: center;
    position: relative;
  }

  .greeting {
    margin: 0px;
    text-align: center;
  }

  .about_me_paragraph {
    justify-content: center;
    word-wrap: break-word;
    font-size: 28px;
    width: auto;
    left: 5%;
    position: relative;
    overflow: hidden;
  }

  .about_me_paragraph_container {
    text-align: center;
    font-size: 30px;
  }
  .heading_container {
    min-height: 700px;
    position: relative;
    overflow: hidden;
  }
  .about_1 {
    padding-left: 20px;
  }
  .about_2 {
    padding-left: 170px;
  }
  .about_3 {
    padding-left: 230px;
  }
  .about_4 {
    padding-left: 400px;
  }
  .about_5 {
    padding-left: 500px;
  }
  .about_6 {
    padding-left: 540px;
  }

  .qualifications {
    background: #bab2b5;
    color: #123c69;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    min-height: 900px;
  }

  .skill_container {
    padding: 50px;
    background-color: #eee2dc;
    border-radius: 10px;
    box-shadow: 10px 20px #888888;
    color: #123c69;
    width: 1000px;
    height: 500px;
    text-align: center;
    align-items: center;
  }

  /*************************************************************************************/

  /****************************************PROJECTS*********************************************/

  .project_container {
    justify-content: center;
    position: relative;
    background: #123c69;
    color: #ac3b61;
    min-height: 1600px;
    height: 1800px;
    align-items: center;
    width: 100%;
  }

  .card_container {
    /* width: 100%; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .project-greetings {
    font-size: 50px;
    padding-top: 40px;
    text-align: center;
  }
  .project-hint {
    padding-top: 30px;
    text-align: center;
    font-size: 30px;
  }

  /*************************************************************************************/

  /****************************************CONTACTS*********************************************/

  .contact {
    background: #a5a0a4;
  }

  .element-container {
    position: relative;
  }

  .contacts_container {
    background: #ac3b61;
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  a.social {
    color: #eee2dc;
    margin: 0 1rem;
    font-size: 30px;
    transition: transform 250ms;
    display: inline-block;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    /* padding: 25px; */
  }

  a.social:hover {
    transform: translateY(-2px);
  }
  a.scroll {
    color: #f06a6c;
    position: sticky;
    left: 49%;
    bottom: 30%;
    transform: translatey(0px);
    overflow-block: hidden;
  }

  .floating {
    animation-name: floating;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    margin-left: 30px;
    margin-top: 3px;
  }

  @keyframes floating {
    from {
      transform: translate(0, 0px);
    }
    65% {
      transform: translate(0, 12px);
    }
    to {
      transform: translate(0, -0px);
    }
  }
}
/*********************************************************************************************/
/*************************************************Default*************************************/
/****************************************LANDING*********************************************/
.landing {
  background: #edc7b7;
  min-height: 100vh;
  height: auto;
  justify-content: center;
}

.landing_wave {
  width: 40%;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

.navigation {
  float: right;
}
.nav {
  height: 100px;
  width: auto;
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin: 20px auto;
  float: right;
}

.buttonCode {
  color: #123c69;
  text-decoration: none;
  background: inherit;
  padding: 15px;
  margin: 15px;
  border-radius: 20px;
  display: inline-block;
  transition: all 0.4s ease 0s;
}

.navLinks {
  cursor: pointer;
}

.buttonCode:hover {
  color: #edc7b7;
  background: #123c69;
  border-color: #123c69;
  transition: all 0.4s ease 0s;
}

.front_image {
  position: absolute;
  margin: auto;
  left: 50%;
  margin-right: auto;
  margin-left: auto;
  max-width: 400px;
  max-height: 300px;
}

.type-container {
  color: #123c69;
  position: absolute;
  pointer-events: none;
  /* top: %; */
  left: 7%;
  /* padding: 100px; */
  overflow: hidden;
  /* max-width: 100%; */
}
.typewriter_name {
  padding: 40px;
}
.typewriter_role {
  padding: 40px;
}
.typewriter_welcome {
  padding: 40px;
}
/*************************************************************************************/

/****************************************ABOUT ME*********************************************/

.welcome {
  background: #bab2b5;
  color: #123c69;
  display: flex;
  justify-content: center;
  min-height: 1000px;
  width: 100%;
}

.element_welcome_container {
  width: 100%;
  justify-content: center;
  position: relative;
}

.greeting {
  margin: 0px;
  text-align: center;
}

.about_me_paragraph {
  justify-content: center;
  word-wrap: break-word;
  font-size: 28px;
  width: auto;
  overflow: hidden;
  position: relative;
}
.about_me_paragraph_container {
  text-align: center;
  font-size: 30px;
  margin: 15px;
}
.heading_container {
  min-height: 700px;
  position: relative;
  overflow: hidden;
}
.about_1 {
  padding-left: 20px;
}
.about_2 {
  padding-left: 150px;
}
.about_3 {
  padding-left: 220px;
}
.about_4 {
  padding-left: 330px;
}
.about_5 {
  padding-left: 430px;
}
.about_6 {
  padding-left: 510px;
}

.qualifications {
  background: #bab2b5;
  color: #123c69;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  min-height: 900px;
}

.skill_container {
  padding: 50px;
  background-color: #eee2dc;
  border-radius: 10px;
  /* box-shadow: 10px 20px #888888; */
  box-shadow: 1px 1px 2px #333;
  width: 50vw;
  color: #123c69;
  /* max-width: 550px; */
  height: 510px;
  text-align: center;
  align-items: center;
}

/*************************************************************************************/

/****************************************PROJECTS*********************************************/

.project_container {
  justify-content: center;
  position: relative;
  background: #123c69;
  color: #ac3b61;
  min-height: 1200px;
  align-items: center;
  width: 100%;
}

.card_container {
  /* width: 100%; */
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.project-greetings {
  font-size: 50px;
  padding-top: 40px;
  text-align: center;
}
.project-hint {
  padding-top: 30px;
  text-align: center;
}

/*************************************************************************************/

/****************************************CONTACTS*********************************************/

.contact {
  background: #a5a0a4;
}

.element-container {
  position: relative;
}

.contacts_container {
  /* width: auto; */
  /* padding: 25px 50px; */
  background: #ac3b61;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* display: flex; */
}

a.social {
  /* position: absolute; */
  /* width: auto;
  top: 50%;
  left: 50%; */
  color: #eee2dc;
  margin: 0 1rem;
  transition: transform 250ms;
  display: inline-block;
  /* padding: 25px; */
}

a.social:hover {
  transform: translateY(-2px);
}
a.scroll {
  color: #f06a6c;
  position: sticky;
  left: 49%;
  bottom: 30%;
  transform: translatey(0px);
  overflow-block: hidden;
}

.floating {
  animation-name: floating;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  margin-left: 30px;
  margin-top: 3px;
}

@keyframes floating {
  from {
    transform: translate(0, 0px);
  }
  65% {
    transform: translate(0, 12px);
  }
  to {
    transform: translate(0, -0px);
  }
}
