.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #123c69;
  overflow-x: hidden;
  transition: 0.5s;

  .closeButton {
    // padding-top: 50px;
    // padding-right: 10px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  .sidenav_btn{
    padding: 15px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #edc7b7;
    display: block;
    transition: 0.3s;
    padding-top: 80px;
  }
}

@-webkit-keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}

@keyframes action {
  0% { transform: translateY(0); }
  100% { transform: translateY(-10px); }
}
