.no_code_icon {
  position: fixed;
}

@media only screen and (max-width: 786px) {
  .card {
    float: left;
    width: 300px;
    min-height: 400px;
    text-align: center;
    background: #ac3b61;
    margin: 20px;
    padding: 15px;
    box-shadow:1px 1px 2px #333;

  }

  .card-title {
    padding-top: 20px;
  }
  .card-image {
    float: left;
    max-width: 400px;
    max-height: 210px;
    min-width: 100%;
    height: 60%;
    transition: 0.2s ease;
    padding-bottom: 10px;

    &:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
  .font_icon {
    color: #edc7b7;

    &:hover {
      color: #123c69;
      transition: all 0.4s ease 0s;
    }
  }
  a {
    color: #edc7b7;
    text-decoration: none;
  }
}

@media only screen and (min-width: 1921px) {
  .card {
    float: left;
    width: 1000px;
    height: 350px;
    font-size: 28px;
    text-align: center;
    background: #ac3b61;
    border-radius: 10px;
    margin: 20px;
    padding: 15px;
  }

  .card-title {
    padding-top: 20px;
  }

  .card-image {
    float: left;
    max-width: 500px;
    max-height: 330px;
    min-height: 320px;
    transition: 0.2s ease;
    padding-bottom: 10px;


    &:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
  .font_icon {
    color: #edc7b7;

    &:hover {
      color: #123c69;
      transition: all 0.4s ease 0s;
    }
  }
  a {
    color: #edc7b7;
    text-decoration: none;
  }
}

@media only screen and (max-width: 1920px) {
  .card {
    float: left;
    width: 600px;
    height: 200px;
    text-align: center;
    background: #ac3b61;
    border-radius: 10px;
    margin: 20px;
    padding: 15px;
  }

  .card-title {
    padding-top: 20px;
  }

  .card-image {
    float: left;
    max-width: 300px;
    max-height: 210px;
    min-height: 200px;
    transition: 0.2s ease;
    padding-bottom: 10px;

    &:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
  .font_icon {
    color: #edc7b7;

    &:hover {
      color: #123c69;
      transition: all 0.4s ease 0s;
    }
  }

  a {
    color: #edc7b7;
    text-decoration: none;
  }
}
